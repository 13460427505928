import React, { useEffect, useState } from 'react';
import './App.css'; // CSS untuk Tailwind dan custom styles
import AOS from 'aos';

const App = () => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState('all');
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false); // State untuk mengontrol pop-up
  const [selectedProduct, setSelectedProduct] = useState(null); // State untuk produk yang dipilih

  useEffect(() => {
    AOS.init({ duration: 900 });
    displayProducts(category);
  }, [category]);

  const displayProducts = async (category) => {
    setLoading(true);
    setNoResults(false);
    const url = `https://checkout.alands.xyz/products?category=${category}`;

    try {
      const response = await fetch(url);
      const products = await response.json();
      
      if (products.length === 0) {
        setNoResults(true);
      }
      setProducts(products);
    } catch (error) {
      console.error('Failed to load products:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchProducts = async (query) => {
    setLoading(true);
    setNoResults(false);
    setSearchQuery(query);
    const url = `https://checkout.alands.xyz/products/search?query=${encodeURIComponent(query)}`;

    try {
      const response = await fetch(url);
      const results = await response.json();
      
      if (results.length === 0) {
        setNoResults(true);
      }
      setProducts(results);
    } catch (error) {
      console.error('Failed to load search results:', error);
      setNoResults(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchProducts(e.target.query.value);
  };

  const reset = () => {
    setSearchQuery('');
    setCategory('all');
    displayProducts('all');
  };

  const handleReadMore = (product) => {
    setSelectedProduct(product);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedProduct(null);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div className="bg-gray-900 text-white">
      {/* Navbar */}
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto">
          <a className="text-xl font-bold" href="#">ZansPiw <span>- Store</span></a>
        </div>
      </nav>

      {/* Carousel */}
      <div className="carousel-inner relative">
        <div className="carousel-item active">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img alt="banner" className="w-full object-cover rounded-lg" src="https://cdn.alands.xyz/files/66e833748f5214a0b86407e1?filename=20240916_203202.jpg" />
          </a>
        </div>
      </div>

      {/* Search Form */}
      <form className="flex justify-center p-4" onSubmit={handleSubmit}>
        <input
          className="form-control mr-2 p-2 rounded text-black"
          style={{color: "black"}}
          type="search"
          name="query"
          placeholder="Search"
          aria-label="Search"
          required
        />
        <button className="bg-green-500 hover:bg-green-600 text-white p-2 rounded" type="submit">Search</button>
        <button className="bg-red-500 hover:bg-red-600 text-white p-2 rounded ml-2" onClick={reset}>Reset</button>
      </form>

      {/* Category Menu */}
      <div className="text-center p-4">
        {['all', 'vps', 'hosting', 'sosmed', 'script', 'private'].map(category => (
          <button key={category} className="bg-blue-500 hover:bg-blue-600 text-white p-2 m-2 rounded" onClick={() => setCategory(category)}>
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      {/* Products Section */}
      <section className="product-category p-4" id="products-section" data-aos="fade-up">
        <div className="container mx-auto">
          <h2>{category.charAt(0).toUpperCase() + category.slice(1)} Products</h2>
          {loading && <p>Loading products...</p>}
          {noResults && <p>No products available in this category.</p>}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2" id="product-list">
            {!loading && products.length > 0 && products.map(product => (
              <div key={product.name} className="bg-gray-800 rounded-lg flex flex-col items-center p-4" data-aos="fade-up">
                <img 
                  src={product.thumbnail || 'https://via.placeholder.com/150'} 
                  alt={product.name} 
                  className="w-32 h-32 object-cover mb-2 rounded-lg" // Ukuran persegi untuk gambar
                />
                <h5 className="font-bold text-center">
                  {product.name.length > 15 ? `${product.name.slice(0, 15)}...` : product.name}
                </h5>
                <p className="text-sm text-center">{product.description.length > 20 ? `${product.description.slice(0, 20)}...` : product.description}</p>
                <p className="font-bold text-center">{formatPrice(product.price) || 'Contact for price'}</p>
                <button 
                  className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded mt-2"
                  onClick={() => handleReadMore(product)} // Memanggil fungsi untuk menampilkan pop-up
                >
                  Read More
                </button>
                <a href={`https://checkout.alands.xyz/detail/${product.name}`} className="bg-green-500 hover:bg-green-600 text-white p-2 rounded mt-2 inline-block">Order Now</a>
              </div>
            ))}
            {!loading && products.length === 0 && (
              <div className="text-center text-gray-400">
                <img src="https://via.placeholder.com/150" alt="No products" className="mx-auto mb-4"/>
                <p>No products available in this category.</p>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Popup untuk detail produk */}
      {popupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-80 relative"> {/* Menambahkan relative */}
            <button 
              onClick={closePopup} 
              className="absolute top-2 right-2 text-white focus:outline-none" // Menempatkan tombol X di dalam kotak
            >
              X
            </button>
            {selectedProduct && (
              <>
                <h5 className="font-bold text-lg">{selectedProduct.name}</h5>
                <p className="text-sm">Deskripsi: {selectedProduct.description}</p>
                <p className="font-bold">Harga: {formatPrice(selectedProduct.price) || 'Contact for price'}</p>
              </>
            )}
          </div>
        </div>
      )}

      {/* Footer */}
      <footer className="bg-gray-800 p-4 text-center">
        <p>&copy; 2024 ZansPiw Store. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default App;
